// Custom Theme for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@include mat.core();

$gnosys-red-palette: (
  50: #f5e5e7,
  100: #e6bfc2,
  200: #c68482,
  300: #ad5755,
  400: #ac3632,
  // standard GNO-SYS red
  500: #a50203,
  600: #9b1817,
  700: #8b0f13,
  800: #7e080d,
  900: #6f0101,
  A100: #f5e5e7,
  A200: #c68482,
  A400: #ac3632,
  A700: #6f0101,
  contrast: (50: rgbs(black, 0.87),
    100: rgbs(black, 0.87),
    200: rgbs(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgbs(black, 0.87),
    A200: rgbs(black, 0.87),
    A400: white,
    A700: white,
  )
);

$gnosys-orange-palette: (
  50: #f4f3ca,
  100: #eedeb6,
  200: #e4c988,
  300: #dbb35b,
  400: #d6a43e,
  // standard GNO-SYS orange
  500: #e0962a,
  600: #cd8c27,
  700: #c77f21,
  800: #c0721d,
  900: #b45e18,
  A100: #eedeb6,
  A200: #e4c988,
  A400: #d6a43e,
  A700: #c77f21,
  contrast: (50: rgbs(black, 0.87),
    100: rgbs(black, 0.87),
    200: rgbs(black, 0.87),
    300: rgbs(black, 0.87),
    400: rgbs(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgbs(black, 0.87),
    A200: rgbs(black, 0.87),
    A400: rgbs(black, 0.87),
    A700: white,
  )
);

$gnosys-pink-palette: (
  50: #ffebed,
  100: #ffccd0,
  200: #f09997,
  300: #e6716f,
  400: #ef504b,
  // standard GNO-SYS pink
  500: #f43f2f,
  // standard GNO-SYS pink 2
  600: #e6180e,
  700: #d32a29,
  800: #c62322,
  900: #b81415,
  A100: #ffccd0,
  A200: #f09997,
  A400: #ef504b,
  A700: #d32a29,
  contrast: (50: rgbs(black, 0.87),
    100: rgbs(black, 0.87),
    200: rgbs(black, 0.87),
    300: rgbs(black, 0.87),
    400: rgbs(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgbs(black, 0.87),
    A200: rgbs(black, 0.87),
    A400: rgbs(black, 0.87),
    A700: white,
  )
);

$gnosys-primary: mat.define-palette($gnosys-red-palette, 500);
$gnosys-accent: mat.define-palette($gnosys-orange-palette, 500, 50);
$gnosys-warn: mat.define-palette($gnosys-pink-palette, 500);

$gnosys-primary-dark: mat.define-palette($gnosys-red-palette, 800);
$gnosys-accent-dark: mat.define-palette($gnosys-orange-palette, 800);
$gnosys-warn-dark: mat.define-palette($gnosys-pink-palette, 700);

$gnosys-light-theme: mat.define-light-theme((color: (primary: $gnosys-primary,
        accent: $gnosys-accent,
        warn: $gnosys-warn,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    ));
$gnosys-dark-theme: mat.define-dark-theme((color: (primary: $gnosys-primary-dark,
        accent: $gnosys-accent-dark,
        warn: $gnosys-warn-dark,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    ));

@include mat.all-component-themes($gnosys-light-theme);

@media (prefers-color-scheme: dark) {
  @include mat.all-component-colors($gnosys-dark-theme);
}