@import "../node_modules/leaflet/dist/leaflet.css";
@import "../node_modules/@aws-amplify/ui-angular/theme.css";
@import "../node_modules/@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
@import "../node_modules/leaflet-geosearch/dist/geosearch.css";
@import "../node_modules/@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

:root {
  color-scheme: light dark;
  background-color: var(--background-color);
  color: var(--font-color);

  --primary-color-0: #f5e5e7;
  --primary-color-1: #e6bfc2;
  --primary-color-2: #c68482;
  --primary-color-3: #ad5755;
  --primary-color-4: #ac3632;
  --primary-color-5: #a50203;
  --primary-color-6: #9b1817;
  --primary-color-7: #8b0f13;
  --primary-color-8: #7e080d;
  --primary-color-9: #6f0101;
  --primary-color-10: #4c0101;

  --shadow-color-0: #f4f3ca;
  --shadow-color-1: #eedeb6;
  --shadow-color-2: #e4c988;
  --shadow-color-3: #dbb35b;
  --shadow-color-4: #d6a43e;
  --shadow-color-5: #e0962a;
  --shadow-color-6: #cd8c27;
  --shadow-color-7: #c77f21;
  --shadow-color-8: #c0721d;
  --shadow-color-9: #b45e18;
  --shadow-color-10: #874712;

  --primary-color: var(--primary-color-5);
  --primary-color-darker: var(--primary-color-8);
  --primary-color-lighter: var(--primary-color-3);
  --shadow-color: var(--shadow-color-5);
  --shadow-color-darker: var(--shadow-color-8);
  --shadow-color-lighter: var(--shadow-color-3);
  --accent-color: var(--shadow-color-0);

  --background-color: #fffafa;
  --background-color-secondary: hsl(0, 11%, 96%);
  --font-color: #1b1b1b;
  --font-color-secondary: #555555;
  --font-color-on-primary: #fff8f8;
  --padding: 12px;

  /* Header customization */
  --header-underline-size: 2px;
  --header-underline-color: var(--primary-color);
  --mat-toolbar-container-background-color: var(--background-color);
  /* --mat-toolbar-title-text-font: Roboto, sans-serif; */
  /* --mat-toolbar-title-text-line-height: 32px; */
  /* --mat-toolbar-title-text-size: 20px; */
  /* --mat-toolbar-title-text-tracking: 0.0125em; */
  /* --mat-toolbar-title-text-weight: 500; */
  /* --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87); */

  /* Main content (beside sidebar) customization */
  --mat-sidenav-content-background-color: var(--background-color);
  /* --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87); */
  /* --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6); */

  /* Sign in dialog customization
  For Amplify css styles see https: //ui.docs.amplify.aws/angular/theming/css-variables
  or https: //ui.docs.amplify.aws/angular/theming/default-theme */
  --amplify-colors-border-primary: var(--shadow-color);
  --amplify-components-tabs-border-color: transparent;
  --amplify-components-tabs-item-border-color: transparent;
  --amplify-components-tabs-item-color: var(--font-color);
  --amplify-components-field-label-color: var(--font-color);
  --amplify-components-input-color: var(--font-color-secondary);
  --amplify-colors-primary-100: var(--primary-color-10);
  --amplify-colors-font-hover: var(--shadow-color-10);
  --amplify-colors-background-primary: var(--background-color);
  --amplify-colors-primary-10: var(--primary-color-1);
  --amplify-colors-primary-20: var(--primary-color-2);
  --amplify-colors-primary-40: var(--primary-color-3);
  --amplify-colors-primary-60: var(--primary-color-lighter);
  --amplify-colors-primary-80: var(--primary-color);
  --amplify-colors-primary-90: var(--primary-color-darker);

  --mdc-elevated-card-container-color: var(--background-color-secondary);
}

@media (prefers-color-scheme: dark) {
  :root {

    --primary-color: var(--primary-color-8);
    --primary-color-darker: var(--primary-color-9);
    --primary-color-lighter: var(--primary-color-6);
    --shadow-color: var(--shadow-color-8);
    --shadow-color-darker: var(--shadow-color-9);
    --shadow-color-lighter: var(--shadow-color-6);
    --accent-color: var(--shadow-color-0);

    --background-color: #282121;
    --background-color-secondary: #332929;
    --font-color: var(--accent-color);
    --font-color-secondary: var(--shadow-color-1);
    --font-color-on-primary: var(--accent-color);
  }
}

* {
  margin: 0px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
}

body {
  position: fixed;
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
}

.leaflet-container .leaflet-control-mouseposition {
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 5px #bbb;
  padding: 0 5px;
  margin: 0;
  color: black;
  font: 11px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}